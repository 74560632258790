@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');

.login-background {
  height: 100vh;
  width: 100vw;
  background-color: #21d4fd;
  background-image: linear-gradient(19deg, #21d4fd 0%, #b721ff 100%);

  font-family: 'Montserrat', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login-welcome {
  color: #fefefe;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-welcome > img {
    width: 200px;
    height: 100%;
  }

.login-welcome > h1 {
  font-weight: lighter;
  font-size: 2rem;
  padding: 0 !important;
  margin: 0 !important;
  line-height: 1;
  text-transform: uppercase;
}

.login-welcome > h2 {
  font-weight: bold;
  font-size: 3rem;
  padding: 0 !important;
  margin: 0 !important;
  line-height: 1;
}

.login-panel{
    display: flex;
    flex-direction: column;
    margin: 40px;
}

.login-panel > input{
    padding: 15px;
    margin: 5px;
    width: 350px;
    height: 50px;
    border-radius: 10px;
    border: none;
    text-decoration: none;
    font-size: 1rem;
}


.btn-grad {
  background-image: linear-gradient(
    to right,
    #232526 0%,
    #414345 51%,
    #232526 100%
  );
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: none;
  width: 350px;
  height: 50px;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
